import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {useAuth0} from "@auth0/auth0-react";

const BlackButton = styled(Button)({
    backgroundColor: '#000000',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#333333'
    }
});

export default function SignInButton(props) {
  const { loginWithRedirect } = useAuth0();


    return (
        <BlackButton {...props} onClick={() => loginWithRedirect()}>
            Sign In
        </BlackButton>
    );
}