import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

export const Auth0ProviderWithNavigate = ({ children }) => {

  const domain = "dev-25xl8nrdq1cdnuxg.us.auth0.com";
  const clientId = "vdNxHkl79zDcq82zE5DjDUuya7ThWiPI";
  const redirectUri = "https://tutapp.site/profile";

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
