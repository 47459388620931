import * as React from 'react';
import LandingPage from "./pages/landing_page/LandingPage";
import {Routes, Route} from 'react-router-dom';
import AccountLandingPage from './pages/account/AccountLandingPage';
import {useAuth0} from "@auth0/auth0-react";
import { AuthenticationGuard } from "./pages/auth/AuthenticationGuard";
import "./styles/GlobalStyle.css"
import {PageLoader} from "./components/PageLoader";


export default function Main() {

  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={() => <AccountLandingPage user={user} />} />}
      />

      {/*<Route path="/callback" element={<CallbackPage />} />*/}


    </Routes>
  )
}
