import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {ThemeProvider} from '@mui/material/styles';
import App from './App';
import theme from './theme';
import {BrowserRouter} from "react-router-dom";
import {Auth0ProviderWithNavigate} from "./pages/auth/Auth0WithNavigate";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <App/>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
    </ThemeProvider>,
);
